import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

function EmailSettings() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    cancellationEmail: '',
    cancellationEmailTemplate: '',
    billingEmail: ''
  });

  useEffect(() => {
    if (user.permissionLevel !== 2) {
      navigate('/dashboard');
    } else {
      fetchSettings();
    }
  }, [user, navigate]);

  const fetchSettings = async () => {
    try {
      const response = await fetch('https://zmc-backend-production.up.railway.app/admin/settings', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        // Update state with fetched data
        setSettings({
          cancellationEmail: data.cancellation_email || '',
          cancellationEmailTemplate: data.cancellation_email_template || '',
          billingEmail: data.billing_email || ''
        });
      } else {
        console.error('Failed to fetch settings:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (content) => {
    setSettings(prev => ({ ...prev, cancellationEmailTemplate: content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://zmc-backend-production.up.railway.app/admin/settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(settings)
      });

      if (response.ok) {
        alert('Email settings updated successfully');
      } else {
        alert('Failed to update email settings');
      }
    } catch (error) {
      console.error('Error updating email settings:', error);
      alert('Failed to update email settings');
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold text-gray-800">Email Settings</h1>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow rounded-lg p-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Cancellation Email</label>
            <input
              type="email"
              name="cancellationEmail"
              value={settings.cancellationEmail}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Cancellation Email Template</label>
            <Editor
              apiKey="qr5qg2ursks26xiuyxk1ubnhfjo5q6jls8gr7zpeff262qmh"
              value={settings.cancellationEmailTemplate}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={handleEditorChange}
            />
            <p>
                Available variables: <br/> 
                <b>%user_message%   %user_name%   %user_email%</b>
            </p>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700">Billing Email</label>
            <input
              type="email"
              name="billingEmail"
              value={settings.billingEmail}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Save Email Settings
          </button>
        </form>
      </main>
    </div>
  );
}

export default EmailSettings;
