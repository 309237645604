import React, { useState, useEffect } from 'react';
import { FiPhone, FiPhoneIncoming, FiPhoneOutgoing, FiPlay, FiInfo, FiFileText, FiDownload, FiRefreshCcw, FiAlertTriangle, FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaCog } from 'react-icons/fa';
import Sidebar from './Sidebar';
import AudioPlayer from './AudioPlayer';
import DatePicker from 'react-datepicker';
import BookingDetailsModal from './BookingDetailsModal';
import CallSummaryModal from './CallSummaryModal';
import TranscriptModal from './TranscriptModal';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '../context/AuthContext';


function CallsPage() {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [businessNumbers, setBusinessNumbers] = useState([]);
  const [selectedBusinessNumber, setSelectedBusinessNumber] = useState('');
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7))); // Default to 7 days ago
  const [endDate, setEndDate] = useState(new Date()); // Default to today
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [transcriptStatus, setTranscriptStatus] = useState({});
  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const [flagMessage, setFlagMessage] = useState('');
  const [selectedCallId, setSelectedCallId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [flaggedCalls, setFlaggedCalls] = useState({});
  const { user } = useAuth();

  const [prevPeriodData, setPrevPeriodData] = useState({
    totalIncomingCalls: 0,
    bookedCalls: 0,
    bookingPercentage: 0,
    lostOpportunity: 0
  });

  useEffect(() => {
    fetchBusinessNumbers();
  }, []);

  useEffect(() => {
    if (selectedBusinessNumber) {
      fetchCalls();
      fetchFlaggedCalls();
    }
  }, [selectedBusinessNumber, startDate, endDate]);

  useEffect(() => {
    const storedFlaggedCalls = JSON.parse(localStorage.getItem('flaggedCalls')) || {};
    setFlaggedCalls(storedFlaggedCalls);
  }, []);

  const getCallOutcome = (call) => {
    if (call.variables && call.variables.Name) return 'Booked';
    if (call.variables && call.variables.Recall_Phone) return 'Recall';
    if (call.variables && call.variables.Phone_Formanager) return 'Complaint';
    if (call.variables && call.variables.Phone_Followup) return 'Followup';
    if (call.variables && call.variables.Reschedule_Phone) return 'Reschedule';
    if (call.variables && call.variables.Cancel_Phone) return 'Cancelled';
    return 'Not Booked';
  };

  const getOutcomeColor = (outcome) => {
    switch (outcome) {
      case 'Booked': return 'bg-green-100 text-green-800';
      case 'Recall': return 'bg-yellow-100 text-yellow-800';
      case 'Complaint': return 'bg-red-100 text-red-800';
      case 'Followup': return 'bg-blue-100 text-blue-800';
      case 'Reschedule': return 'bg-purple-100 text-purple-800';
      case 'Cancelled': return 'bg-orange-100 text-orange-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  useEffect(() => {
    const filtered = calls.filter(call => 
      call.from.includes(searchTerm) || 
      getCallOutcome(call).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCalls(filtered);
  }, [calls, searchTerm]);

  const fetchBusinessNumbers = async () => {
    try {
      const response = await fetch('https://zmc-backend-production.up.railway.app/user/business-numbers', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch business numbers');
      }
      const data = await response.json();
      setBusinessNumbers(data.businessNumbers);
      if (data.businessNumbers.length > 0) {
        setSelectedBusinessNumber(data.businessNumbers[0].number);
      }
    } catch (err) {
      console.error('Error fetching business numbers:', err);
      setError('Failed to fetch business numbers');
    }
  };

  const fetchCalls = async (start, end) => {
    if (!selectedBusinessNumber || !start || !end) {
      return []; // Return empty array if we don't have all required data
    }

    setLoading(true);
    setError(null);
    const options = {
      method: 'GET',
      headers: {
        authorization: 'sk-fqlnz6nuzcky3uzj0qjhhvdqktjdvf2zyh3b276kifvgwklhg5oyfkxak3olzvab69'
      }
    };

    const queryParams = new URLSearchParams({
      to_number: selectedBusinessNumber,
      start_date: start.toISOString(),
      end_date: end.toISOString()
    });

    try {
      const response = await fetch(`https://api.bland.ai/v1/calls?${queryParams}`, options);
      if (!response.ok) {
        throw new Error('Failed to fetch calls');
      }
      const data = await response.json();
      return data.calls || [];
    } catch (err) {
      console.error('Error fetching calls:', err);
      setError('Failed to fetch calls');
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Add this function to fetch flagged calls
  const fetchFlaggedCalls = async () => {
    try {
      const response = await fetch('https://zmc-backend-production.up.railway.app/flagged-calls', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch flagged calls');
      }
      const data = await response.json();
      const flaggedCallsMap = data.flaggedCallIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      setFlaggedCalls(flaggedCallsMap);
    } catch (error) {
      console.error('Error fetching flagged calls:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const formatDuration = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hrs > 0 ? hrs + 'h ' : ''}${mins}m`;
  };

  const totalIncomingCalls = calls.filter(call => call.inbound).length;
  const bookedCalls = calls.filter(call => call.inbound && call.variables && (call.variables.Name || call.variables.Phone)).length;
  const bookingPercentage = totalIncomingCalls > 0 ? (bookedCalls / totalIncomingCalls * 100) : 0;
  const lostOpportunity = 100 - bookingPercentage;

  const handleViewBookingDetails = (booking) => {
    setSelectedBooking(booking);
  };

  const handleViewSummary = (summary) => {
    setSelectedSummary(summary);
  };

  const fetchTranscript = async (callId) => {
    if (user.permissionLevel < 1) return;
    
    setTranscriptStatus(prev => ({ ...prev, [callId]: 'loading' }));
    
    const options = {
      method: 'GET',
      headers: {
        authorization: 'sk-fqlnz6nuzcky3uzj0qjhhvdqktjdvf2zyh3b276kifvgwklhg5oyfkxak3olzvab69'
      }
    };

    try {
      const response = await fetch(`https://api.bland.ai/v1/calls/${callId}/correct`, options);
      if (!response.ok) {
        throw new Error('Failed to fetch transcript');
      }
      const data = await response.json();
      setSelectedTranscript(data);
      setTranscriptStatus(prev => ({ ...prev, [callId]: 'success' }));
    } catch (error) {
      console.error('Error fetching transcript:', error);
      setTranscriptStatus(prev => ({ ...prev, [callId]: 'error' }));
    }
  };

  const handleFlagClick = (callId) => {
    setSelectedCallId(callId);
    setFlagModalOpen(true);
  };

  const handleFlagSubmit = async () => {
    try {
      const response = await fetch('https://zmc-backend-production.up.railway.app/flag-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          callId: selectedCallId,
          message: flagMessage
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to flag call');
      }
  
      // Update local state
      setFlaggedCalls(prev => ({ ...prev, [selectedCallId]: true }));
  
      setFlagModalOpen(false);
      setFlagMessage('');
      alert('Call flagged successfully');
  
      // Make the webhook call
      await fetch('https://hook.us1.make.com/kogadnlmd1t7odd2hs2o2mp6jqvdlfb4', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          callId: selectedCallId,
          callSummary: calls.find(call => call.c_id === selectedCallId)?.summary,
          callRecordingUrl: calls.find(call => call.c_id === selectedCallId)?.recording_url,
          userMessage: flagMessage
        }),
      });
    } catch (error) {
      console.error('Error flagging call:', error);
      alert('Failed to flag call');
    }
  };

  const calculateMetrics = (callsData) => {
    if (!callsData || callsData.length === 0) {
      return {
        totalIncomingCalls: 0,
        bookedCalls: 0,
        bookingPercentage: 0,
        lostOpportunity: 0
      };
    }

    const incomingCalls = callsData.filter(call => call.inbound);
    const totalIncoming = incomingCalls.length;
    const outcomes = incomingCalls.map(getCallOutcome);
    const booked = outcomes.filter(outcome => outcome === 'Booked').length;
    const lostOpportunity = outcomes.filter(outcome => outcome === 'Not Booked').length;
    const bookingPercentage = totalIncoming > 0 ? (booked / totalIncoming * 100) : 0;
    const lostOpportunityPercentage = totalIncoming > 0 ? (lostOpportunity / totalIncoming * 100) : 0;

    return {
      totalIncomingCalls: totalIncoming,
      bookedCalls: booked,
      bookingPercentage,
      lostOpportunity: lostOpportunityPercentage
    };
  };

  const fetchPreviousPeriodData = async () => {
    const currentPeriodDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const prevPeriodEndDate = new Date(startDate);
    const prevPeriodStartDate = new Date(prevPeriodEndDate);
    prevPeriodStartDate.setDate(prevPeriodStartDate.getDate() - currentPeriodDays);

    const prevPeriodCalls = await fetchCalls(prevPeriodStartDate, prevPeriodEndDate);
    const metrics = calculateMetrics(prevPeriodCalls);
    setPrevPeriodData(metrics);
  };

  useEffect(() => {
    if (selectedBusinessNumber) {
      const fetchData = async () => {
        const currentPeriodCalls = await fetchCalls(startDate, endDate);
        setCalls(currentPeriodCalls);
        await fetchPreviousPeriodData();
      };
      fetchData();
    }
  }, [selectedBusinessNumber, startDate, endDate]);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const renderPercentageChange = (current, previous, inverse = false) => {
    const change = calculatePercentageChange(current, previous);
    const isPositive = change > 0;
    const color = inverse 
      ? (isPositive ? 'text-red-500' : 'text-green-500')
      : (isPositive ? 'text-green-500' : 'text-red-500');
    const Arrow = isPositive ? FiArrowUp : FiArrowDown;
    return (
      <span className={`${color} flex items-center text-sm ml-2`}>
        <Arrow className="mr-1" />
        {Math.abs(change).toFixed(2)}%
      </span>
    );
  };

  const metrics = calculateMetrics(calls);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">Call History</h1>
        
        <div className="mb-6 flex items-center space-x-4">
          <div>
            <label htmlFor="businessNumber" className="block text-sm font-medium text-gray-700">Select a business number:</label>
            <select
              id="businessNumber"
              value={selectedBusinessNumber}
              onChange={(e) => setSelectedBusinessNumber(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {businessNumbers.map((number) => (
                <option key={number.id} value={number.number}>
                  {number.nickname ? `${number.nickname} (${number.number})` : number.number}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Date range:</label>
            <div className="flex items-center space-x-2">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
              <span>to</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Search For:</label>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                placeholder="Search phone number or 'booked'"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4 mb-6">
          <div className="bg-white shadow rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">Total Incoming Calls</h2>
            <div className="flex items-center">
              <p className="text-3xl font-bold">{metrics.totalIncomingCalls}</p>
              {renderPercentageChange(metrics.totalIncomingCalls, prevPeriodData.totalIncomingCalls)}
            </div>
          </div>
          <div className="bg-white shadow rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">Booked Calls</h2>
            <div className="flex items-center">
              <p className="text-3xl font-bold">{metrics.bookedCalls}</p>
              {renderPercentageChange(metrics.bookedCalls, prevPeriodData.bookedCalls)}
            </div>
          </div>
          <div className="bg-white shadow rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">Booking Percentage</h2>
            <div className="flex items-center">
              <p className="text-3xl font-bold">{metrics.bookingPercentage.toFixed(2)}%</p>
              {renderPercentageChange(metrics.bookingPercentage, prevPeriodData.bookingPercentage)}
            </div>
          </div>
          <div className="bg-white shadow rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">Lost Opportunity</h2>
            <div className="flex items-center">
              <p className="text-3xl font-bold">{metrics.lostOpportunity.toFixed(2)}%</p>
              {renderPercentageChange(metrics.lostOpportunity, prevPeriodData.lostOpportunity, true)}
            </div>
          </div>
        </div>

        {loading ? (
          <p className="text-center text-gray-500">Loading calls...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : filteredCalls.length === 0 ? (
          <p className="text-center text-gray-500">No calls found for the selected criteria.</p>
        ) : (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">From</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recording</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Outcome</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {filteredCalls.map((call) => (
                  <tr key={call.c_id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div>
                        {call.from}
                        {user.permissionLevel >= 1 && (
                          <div className="text-xs text-gray-500">ID: {call.c_id}</div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {call.completed ? (
                        call.inbound ? (
                          <FiPhoneIncoming className="text-green-500" />
                        ) : (
                          <FiPhoneOutgoing className="text-blue-500" />
                        )
                      ) : (
                        <FiRefreshCcw className="text-blue-500 animate-spin" />
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{call.completed ? formatDuration(call.call_length) : "In progress"}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(call.created_at)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {call.completed ? (
                        <>
                          {call.recording_url && (
                            <button
                              onClick={() => setSelectedRecording(call.recording_url)}
                              className="text-green-500 hover:text-green-700"
                            >
                              <FiPlay />
                            </button>
                          )}
                          {user.permissionLevel >= 1 && call.recording_url && (
                            <button><a
                              href={call.recording_url}
                              target="blank"
                              download
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <FiDownload />
                            </a></button>
                          )}
                          {call.summary && (
                            <button
                              onClick={() => handleViewSummary(call.summary)}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <FiFileText />
                            </button>
                          )}
                          {user.permissionLevel >= 1 && (
                            <button
                              onClick={() => fetchTranscript(call.c_id)}
                              className="text-purple-500 hover:text-purple-700"
                            >
                              {transcriptStatus[call.c_id] === 'loading' ? (
                                <AiOutlineLoading3Quarters className="animate-spin" />
                              ) : transcriptStatus[call.c_id] === 'success' ? (
                                <FiInfo />
                              ) : (
                                <FaCog />
                              )}
                            </button>
                          )}
                        </>
                      ) : (
                        "In progress"
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                    {call.completed ? (
                      <div className="flex items-center">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getOutcomeColor(getCallOutcome(call))}`}>
                          {getCallOutcome(call)}
                        </span>
                        {getCallOutcome(call) === 'Booked' && (
                          <button
                            onClick={() => handleViewBookingDetails(call.variables)}
                            className="ml-2 text-blue-500 hover:text-blue-700"
                          >
                            <FiInfo />
                          </button>
                        )}
                        {flaggedCalls[call.c_id] ? (
                          <span className="ml-2 text-yellow-500">Flagged</span>
                        ) : (
                          <button
                            onClick={() => handleFlagClick(call.c_id)}
                            className="ml-2 text-yellow-500 hover:text-yellow-700"
                          >
                            <FiAlertTriangle />
                          </button>
                        )}
                      </div>
                    ) : (
                      "In progress"
                    )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {selectedRecording && (
          <AudioPlayer
            url={selectedRecording}
            onClose={() => setSelectedRecording(null)}
          />
        )}
        {selectedBooking && (
          <BookingDetailsModal
            booking={selectedBooking}
            onClose={() => setSelectedBooking(null)}
          />
        )}
        {selectedSummary && (
          <CallSummaryModal
            summary={selectedSummary}
            onClose={() => setSelectedSummary(null)}
          />
        )}
        {selectedTranscript && (
          <TranscriptModal
            transcript={selectedTranscript}
            onClose={() => setSelectedTranscript(null)}
          />
        )}
        {flagModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-xl p-6 w-96">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Flag Call</h3>
              <textarea
                value={flagMessage}
                onChange={(e) => setFlagMessage(e.target.value)}
                placeholder="Enter your message"
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                rows="4"
              />
              <div className="mt-4 flex justify-end gap-x-2">
                <button
                  onClick={handleFlagSubmit}
                  className="px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                >
                  Flag Call
                </button>
                <button
                  onClick={() => setFlagModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default CallsPage;