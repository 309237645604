import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import CallsPage from './components/CallsPage';
import Profile from './components/Profile';
import Admin from './components/Admin';
import Manager from './components/Manager';
import ManageUser from './components/ManageUser';
import Billing from './components/Billing';
import AdminSettings from './components/AdminSettings';
import EmailSettings from './components/EmailSettings';
import DevPage from './components/DevPage';
import PathwayUpdate from './components/PathwayUpdate';
import VersionUpdate from './components/VersionUpdate';
import { AuthProvider, useAuth } from './context/AuthContext';

const PrivateRoute = ({ children, requiredPermission = 0, requireDev = false }) => {
  const { isAuthenticated, user, loading } = useAuth();
  
  console.log('PrivateRoute conditions:', {
    isAuthenticated,
    userPermissionLevel: user?.permissionLevel,
    requiredPermission,
    userDev: user?.dev,
    requireDev
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (user.permissionLevel < requiredPermission || (requireDev && user.dev !== "1")) {
    console.log('Access denied');
    return <Navigate to="/calls" />;
  }
  
  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/calls" 
            element={
              <PrivateRoute>
                <CallsPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/profile" 
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/admin" 
            element={
              <PrivateRoute requiredPermission={2}>
                <Admin />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/manager" 
            element={
              <PrivateRoute requiredPermission={1}>
                <Manager />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/manage-user/:id" 
            element={
              <PrivateRoute requiredPermission={1}>
                <ManageUser />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/billing" 
            element={
              <PrivateRoute>
                <Billing />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/admin/settings" 
            element={<PrivateRoute requiredPermission={2}><AdminSettings /></PrivateRoute>}
          />
          <Route 
            path="/admin/settings/email" 
            element={<PrivateRoute requiredPermission={2}><EmailSettings /></PrivateRoute>}
          />
          <Route 
            path="/dev/update" 
            element={
              <PrivateRoute requiredPermission={2} requireDev={true}>
                <PathwayUpdate />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dev/version-update" 
            element={
              <PrivateRoute requiredPermission={2} requireDev={true}>
                <VersionUpdate />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dev" 
            element={
              <PrivateRoute requiredPermission={2} requireDev={true}>
                <DevPage />
              </PrivateRoute>
            } 
          />
          <Route path="/" element={<Navigate to="/calls" />} />
          <Route path="*" element={<Navigate to="/calls" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
