import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar';
import { useNavigate, Link } from 'react-router-dom';

function AdminSettings() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    test: ''
  });

  useEffect(() => {
    if (user.permissionLevel !== 2) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Optionally, update the test field to the backend, if needed
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold text-gray-800">Admin Settings</h1>
          <nav className="flex space-x-4">
            <Link to="/admin/settings" className="text-blue-500 hover:text-blue-700">General</Link>
            <Link to="/admin/settings/email" className="text-blue-500 hover:text-blue-700">Email</Link>
          </nav>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow rounded-lg p-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Test Field</label>
            <input
              type="text"
              name="test"
              value={settings.test}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Save Settings
          </button>
        </form>
      </main>
    </div>
  );
}

export default AdminSettings;
