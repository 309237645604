import React from 'react';
import { FiX } from 'react-icons/fi';

function TranscriptModal({ transcript, onClose }) {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="transcript-modal">
      <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Call Transcript</h3>
          <button 
            onClick={onClose} 
            className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-600"
          >
            <FiX />
          </button>
          <div className="mt-2 px-7 py-3">
            {transcript.corrected.map((item, index) => (
              <div key={index} className="mb-4">
                <p className="text-sm text-gray-500">
                  <span className="font-semibold">{item.speaker === 0 ? 'User' : 'Agent'}:</span> {item.text}
                </p>
                <p className="text-xs text-gray-400">
                  Timeframe: {item.start.toFixed(2)} - {item.end.toFixed(2)} seconds
                </p>
              </div>
            ))}
          </div>
          <div className="items-center px-4 py-3">
            <button
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TranscriptModal;
