import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar';
import { FiExternalLink, FiPlus, FiCreditCard, FiTrash2 } from 'react-icons/fi';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_1R2ZcGTaz7BCaHWx37u35fxy');

function Billing() {
  const { user } = useAuth();
  const [billingData, setBillingData] = useState({
    transactions: [],
    subscriptions: [],
    paymentMethods: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({
    makeDefault: false
  });
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const cardElementRef = useRef(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [cancellationMessage, setCancellationMessage] = useState('');

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
      setElements(stripeInstance.elements());
    };

    initializeStripe();
  }, []);

  useEffect(() => {
    if (elements && cardElementRef.current) {
      const cardElement = elements.create('card');
      cardElement.mount(cardElementRef.current);
      return () => cardElement.unmount();
    }
  }, [elements, isAddPaymentModalOpen]);

  const fetchBillingInfo = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://zmc-backend-production.up.railway.app/user/billing-info', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch billing information');
      }
      const data = await response.json();
      setBillingData({
        transactions: data.transactions || [],
        subscriptions: data.subscriptions || [],
        paymentMethods: data.paymentMethods || [],
        defaultPaymentMethod: data.defaultPaymentMethod
      });
    } catch (error) {
      console.error('Error fetching billing information:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();
    setError(null);
  
    if (!stripe || !elements) {
      setError('Stripe has not been initialized yet. Please try again.');
      return;
    }
  
    const cardElement = elements.getElement('card');
    
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (error) {
        throw error;
      }
  
      const response = await fetch('https://zmc-backend-production.up.railway.app/user/add-payment-method', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          makeDefault: newCardDetails.makeDefault
        })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || 'Failed to add payment method');
      }
  
      // Update your local state with the new payment method
      setBillingData(prevData => ({
        ...prevData,
        paymentMethods: [...prevData.paymentMethods, data.paymentMethod]
      }));
  
      setIsAddPaymentModalOpen(false);
      cardElement.clear();
      setNewCardDetails({
        makeDefault: false
      });
      alert('Payment method added successfully!');
    } catch (error) {
      console.error('Error adding payment method:', error);
      setError(error.message || 'An error occurred while adding the payment method. Please try again.');
    }
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    if (!window.confirm("Are you sure you want to delete this payment method?")) {
      return;
    }
  
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/user/delete-payment-method`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ paymentMethodId })
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete payment method');
      }
  
      // Remove the deleted payment method from the local state
      setBillingData(prevData => ({
        ...prevData,
        paymentMethods: prevData.paymentMethods.filter(method => method.id !== paymentMethodId)
      }));
  
      alert('Payment method deleted successfully!');
    } catch (error) {
      console.error('Error deleting payment method:', error);
      setError(error.message || 'An error occurred while deleting the payment method. Please try again.');
    }
  };
  
  const handleCancelClick = (subscriptionId) => {
    setSelectedSubscription(subscriptionId);
  };

  const handleRequestCancellation = async () => {
    try {
      const response = await fetch('https://zmc-backend-production.up.railway.app/user/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          subscriptionId: selectedSubscription,
          message: cancellationMessage
        })
      });

      if (!response.ok) {
        throw new Error('Failed to request cancellation');
      }

      alert('Cancellation requested successfully');
      setCancellationMessage('');
      setSelectedSubscription(null);
    } catch (error) {
      console.error('Error requesting cancellation:', error);
      alert('Failed to request cancellation');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">Billing</h1>

        {/* Payment Methods */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Your Payment Methods</h2>
          {billingData.paymentMethods.length > 0 ? (
            <ul className="space-y-2">
              {billingData.paymentMethods.map((method) => (
                <li key={method.id} className="flex items-center justify-between border-b pb-2">
                  <div className="flex items-center">
                    <span className="mr-2">
                      {method.card.brand.toUpperCase()} **** **** **** {method.card.last4}
                    </span>
                    <span className="text-sm text-gray-500">
                      Expires {method.card.exp_month}/{method.card.exp_year}
                    </span>
                    {method.id === billingData.defaultPaymentMethod && (
                      <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Default
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() => handleDeletePaymentMethod(method.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FiTrash2 />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No payment methods found.</p>
          )}
          <button
            onClick={() => setIsAddPaymentModalOpen(true)}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center"
          >
            <FiPlus className="mr-2" /> Add Payment Method
          </button>
          <p className="mt-2 text-sm text-gray-600">
            You can securely add your credit card information to enable automatic payments & invoices for our services.
          </p>
        </div>

       {/* Active Subscriptions */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Active Subscriptions</h2>
          {billingData.subscriptions.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscription</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interval</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Next Charge</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {billingData.subscriptions.map((subscription) => (
                  <tr key={subscription.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{subscription.description || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">${(subscription.plan.amount / 100).toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{subscription.plan.interval}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{subscription.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleCancelClick(subscription.id)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No active subscriptions found.</p>
          )}
        </div>

        {/* Transactions */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Transactions</h2>
          {billingData.transactions.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Receipt</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {billingData.transactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{new Date(transaction.created * 1000).toLocaleDateString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap">${(transaction.amount / 100).toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{transaction.description || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {transaction.receipt_url && (
                        <a
                          href={transaction.receipt_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <FiExternalLink className="inline-block mr-1" /> View Receipt
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No transactions found.</p>
          )}
        </div>
      </main>

          {/* Add Payment Method Modal */}
    {isAddPaymentModalOpen && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Add Payment Method</h3>
            <form onSubmit={handleAddPaymentMethod} className="mt-2 text-left">
              <div className="mb-4">
                <label htmlFor="card-element" className="block text-sm font-medium text-gray-700">Credit or debit card</label>
                <div ref={cardElementRef} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {/* Stripe Elements will be inserted here */}
                </div>
              </div>
              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={newCardDetails.makeDefault}
                    onChange={(e) => setNewCardDetails({...newCardDetails, makeDefault: e.target.checked})}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-sm text-gray-700">Make this your default payment option</span>
                </label>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Add Payment Method
                </button>
              </div>
            </form>
            <button
              onClick={() => setIsAddPaymentModalOpen(false)}
              className="mt-3 px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )}
    
    {/* Cancellation Modal */}
    {selectedSubscription && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl p-6 w-96">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Cancellation Request</h3>
            <textarea
              value={cancellationMessage}
              onChange={(e) => setCancellationMessage(e.target.value)}
              placeholder="Reason for cancellation"
              className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              rows="4"
            />
            <div className="mt-4 flex justify-end gap-x-2">
              <button
                onClick={handleRequestCancellation}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Request Cancellation
              </button>
              <button
                onClick={() => setSelectedSubscription(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Billing;
