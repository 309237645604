import React from 'react';
import { FiX } from 'react-icons/fi';

function BookingDetailsModal({ booking, onClose }) {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Booking Details</h3>
          <button 
            onClick={onClose} 
            className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-600"
          >
            <FiX />
          </button>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500 mb-1">
              <strong>Name:</strong> {booking.Name}
            </p>
            <p className="text-sm text-gray-500 mb-1">
              <strong>Phone:</strong> {booking.Phone}
            </p>
            <p className="text-sm text-gray-500 mb-1">
              <strong>Address:</strong> {booking.Address}
            </p>
            <p className="text-sm text-gray-500 mb-1">
              <strong>Appliance:</strong> {booking.Appliance}
            </p>
            <p className="text-sm text-gray-500 mb-1">
              <strong>Issue:</strong> {booking.Issue}
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingDetailsModal;