import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar';
import { FiEdit2, FiTrash2, FiSave, FiX, FiPlus, FiExternalLink } from 'react-icons/fi';
import { Editor } from '@tinymce/tinymce-react';

function ManageUser() {
  const { id } = useParams();
  const { user: currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingStripeId, setEditingStripeId] = useState(false);
  const [businessNumbers, setBusinessNumbers] = useState([]);
  const [newBusinessNumber, setNewBusinessNumber] = useState({ number: '', nickname: '' });
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [newSubscription, setNewSubscription] = useState({
    priceUSD: '',
    serviceType: '',
    title: ''
  });
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [newPayment, setNewPayment] = useState({
    priceUSD: '',
    serviceType: '',
    title: ''
  });
  const [recurringPayments, setRecurringPayments] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  useEffect(() => {
    if (currentUser.permissionLevel < 1) {
      window.location.href = '/dashboard';
    } else {
      fetchUserData();
      fetchBusinessNumbers();
      fetchNotes();
      fetchSubscriptions();
      fetchTransactions();
      fetchRecurringPayments();
      fetchPaymentMethods();
    }
  }, [id, currentUser]);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setUser(data.user);
      // After setting the user data, fetch payment methods
      if (data.user.stripe_customer_id) {
        fetchPaymentMethods(data.user.stripe_customer_id);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchBusinessNumbers = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/business-numbers`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch business numbers');
      }
      const data = await response.json();
      setBusinessNumbers(data.businessNumbers);
    } catch (error) {
      console.error('Error fetching business numbers:', error);
    }
  };

  const fetchNotes = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/notes`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch notes');
      }
      const data = await response.json();
      setNotes(data.notes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/subscriptions`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch subscriptions');
      }
      const data = await response.json();
      setSubscriptions(data.subscriptions);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/transactions`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch transactions');
      }
      const data = await response.json();
      setTransactions(data.transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const fetchRecurringPayments = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/recurring-payments`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch recurring payments');
      }
      const data = await response.json();
      setRecurringPayments(data.recurringPayments);
    } catch (error) {
      console.error('Error fetching recurring payments:', error);
    }
  };

  
  const fetchPaymentMethods = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/payment-methods`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch payment methods');
      }
      const data = await response.json();
      setPaymentMethods(data.paymentMethods);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };
  

  const handleUpdateUser = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          fullName: user.full_name,
          email: user.email,
          companyName: user.company_name,
          phoneNumber: user.phone_number,
          permissionLevel: user.permission_level,
          stripeCustomerId: user.stripe_customer_id
        })
      });
      if (!response.ok) {
        throw new Error('Failed to update user');
      }
      setEditing(false);
      setEditingStripeId(false);
      fetchUserData();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleAddBusinessNumber = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/business-numbers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(newBusinessNumber)
      });
      if (!response.ok) {
        throw new Error('Failed to add business number');
      }
      setNewBusinessNumber({ number: '', nickname: '' });
      fetchBusinessNumbers();
    } catch (error) {
      console.error('Error adding business number:', error);
    }
  };

  const handleUpdateBusinessNumber = async (numberId, updatedNumber) => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/business-numbers/${numberId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(updatedNumber)
      });
      if (!response.ok) {
        throw new Error('Failed to update business number');
      }
      fetchBusinessNumbers();
    } catch (error) {
      console.error('Error updating business number:', error);
    }
  };

  const handleDeleteBusinessNumber = async (numberId) => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/business-numbers/${numberId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to delete business number');
      }
      fetchBusinessNumbers();
    } catch (error) {
      console.error('Error deleting business number:', error);
    }
  };

  const handleAddNote = async () => {
    try {
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/notes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ content: newNote })
      });
      if (!response.ok) {
        throw new Error('Failed to add note');
      }
      setNewNote('');
      fetchNotes();
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleCreateSubscription = async () => {
    try {
      const subscriptionData = {
        ...newSubscription,
        priceUSD: parseFloat(newSubscription.priceUSD),
        title: `${newSubscription.title} - ${user.company_name}`
      };
  
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/subscriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(subscriptionData)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create subscription');
      }
  
      const data = await response.json();
      alert(`Subscription created! Payment link: ${data.paymentLink}`);
  
      setIsSubscriptionModalOpen(false);
      setNewSubscription({ priceUSD: '', serviceType: '', title: '' });
      fetchSubscriptions();
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert(`Failed to create subscription: ${error.message}`);
    }
  };
  
  const handleCreateRecurringPayment = async () => {
    try {
      const subscriptionData = {
        priceUSD: parseFloat(newPayment.priceUSD),
        serviceType: newPayment.serviceType,
        title: newPayment.title,
        paymentMethodId: selectedPaymentMethod,
        currency: 'usd'  // Add this line to explicitly set the currency
      };
  
      const response = await fetch(`https://zmc-backend-production.up.railway.app/admin/users/${id}/subscriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(subscriptionData)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create subscription');
      }
  
      const data = await response.json();
      alert(`Subscription created successfully!\nSubscription ID: ${data.subscription.id}`);
  
      setIsPaymentModalOpen(false);
      setNewPayment({ priceUSD: '', serviceType: '', title: '' });
      setSelectedPaymentMethod('');
      
      fetchSubscriptions();
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert(`Failed to create subscription: ${error.message}`);
    }
  };
  

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">Manage User: {user.full_name}</h1>
        
        {/* User Information */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">User Information</h2>
          {editing ? (
            <form onSubmit={(e) => { e.preventDefault(); handleUpdateUser(); }} className="space-y-4">
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  value={user.full_name}
                  onChange={(e) => setUser({ ...user, full_name: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  value={user.company_name}
                  onChange={(e) => setUser({ ...user, company_name: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={user.phone_number}
                  onChange={(e) => setUser({ ...user, phone_number: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <label htmlFor="stripeCustomerId" className="block text-sm font-medium text-gray-700">Stripe Customer ID</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    id="stripeCustomerId"
                    value={user.stripe_customer_id || ''}
                    onChange={(e) => setUser({ ...user, stripe_customer_id: e.target.value })}
                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                    disabled={!editingStripeId}
                  />
                  <button
                    type="button"
                    onClick={() => setEditingStripeId(!editingStripeId)}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {editingStripeId ? 'Lock' : 'Edit'}
                  </button>
                </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => { setEditing(false); setEditingStripeId(false); }}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save Changes
                </button>
              </div>
            </form>
          ) : (
            <div className="space-y-4">
              <p className="text-gray-600"><span className="font-semibold">Full Name:</span> {user.full_name}</p>
              <p className="text-gray-600"><span className="font-semibold">Email:</span> {user.email}</p>
              <p className="text-gray-600"><span className="font-semibold">Company:</span> {user.company_name}</p>
              <p className="text-gray-600"><span className="font-semibold">Phone:</span> {user.phone_number}</p>
              <p className="text-gray-600"><span className="font-semibold">Stripe Customer ID:</span> {user.stripe_customer_id || 'Not set'}</p>
              <button
                onClick={() => setEditing(true)}
                className="mt-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Edit User
              </button>
            </div>
          )}
        </div>

        {/* Business Numbers */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Business Numbers</h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="New number"
              value={newBusinessNumber.number}
              onChange={(e) => setNewBusinessNumber({ ...newBusinessNumber, number: e.target.value })}
              className="mr-2 px-3 py-2 border border-gray-300 rounded-md"
            />
            <input
              type="text"
              placeholder="Nickname"
              value={newBusinessNumber.nickname}
              onChange={(e) => setNewBusinessNumber({ ...newBusinessNumber, nickname: e.target.value })}
              className="mr-2 px-3 py-2 border border-gray-300 rounded-md"
            />
            <button
              onClick={handleAddBusinessNumber}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Add Number
            </button>
          </div>
          <ul className="space-y-2">
            {businessNumbers.map((number) => (
              <li key={number.id} className="flex items-center justify-between">
                <span>{number.number} ({number.nickname})</span>
                <div>
                  <button
                    onClick={() => {
                        const updatedNumber = prompt('Enter new number:', number.number);
                        const updatedNickname = prompt('Enter new nickname:', number.nickname);
                        if (updatedNumber && updatedNickname) {
                          handleUpdateBusinessNumber(number.id, { number: updatedNumber, nickname: updatedNickname });
                        }
                      }}
                      className="text-blue-500 hover:text-blue-700 mr-2"
                    >
                      <FiEdit2 />
                    </button>
                    <button
                      onClick={() => handleDeleteBusinessNumber(number.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FiTrash2 />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>

        {/* Recurring Payments */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">User Subscriptions</h2>
          <button
            onClick={() => setIsPaymentModalOpen(true)}
            className="mb-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            <FiPlus className="inline-block mr-2" /> Create New Subscription
          </button>
          <ul className="space-y-2">
            {subscriptions.map((subscription) => (
                <li key={subscription.id} className="flex items-center justify-between border-b pb-2">
                  <span className="font-medium">{subscription.name}</span>
                  <span className="text-gray-600">Status: {subscription.status}</span>
                  <span className="text-gray-600">Next Billing Date: {new Date(subscription.nextBillingDate).toLocaleDateString()}</span>
                </li>
              ))}
          </ul>
        </div>
  
        {/* Transactions */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Transactions</h2>
          <ul className="space-y-2">
            {transactions.map((transaction) => (
              <li key={transaction.id} className="flex items-center justify-between">
                <span>{transaction.amount / 100} {transaction.currency.toUpperCase()}</span>
                <span>{new Date(transaction.created * 1000).toLocaleString()}</span>
              </li>
            ))}
          </ul>
        </div>
  
        {/* Notes */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Notes</h2>
          <div className="mb-4">
            <Editor
              apiKey="qr5qg2ursks26xiuyxk1ubnhfjo5q6jls8gr7zpeff262qmh"
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              value={newNote}
              onEditorChange={(content) => setNewNote(content)}
            />
            <button
              onClick={handleAddNote}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Add Note
            </button>
          </div>
          <ul className="space-y-4">
            {notes.map((note) => (
              <li key={note.id} className="border-b pb-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-semibold">{note.author}</span>
                  <span className="text-sm text-gray-500">{new Date(note.timestamp).toLocaleString()}</span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: note.content }} />
              </li>
            ))}
          </ul>
        </div>
      </main>
  
      {/* Subscription Modal */}
      {isSubscriptionModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Create Subscription</h3>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="priceUSD" className="block text-sm font-medium text-gray-700">Price (USD)</label>
                    <input
                      type="number"
                      id="priceUSD"
                      value={newSubscription.priceUSD}
                      onChange={(e) => setNewSubscription({ ...newSubscription, priceUSD: e.target.value })}
                      placeholder="Enter price in USD"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceType" className="block text-sm font-medium text-gray-700">Service Type</label>
                    <select
                      id="serviceType"
                      value={newSubscription.serviceType}
                      onChange={(e) => setNewSubscription({ ...newSubscription, serviceType: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select a service type</option>
                      <option value="missed_call_answering">Missed Call Answering</option>
                      <option value="full_service_phone_line">Full-Service Phone Line</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                      type="text"
                      id="title"
                      value={newSubscription.title}
                      onChange={(e) => setNewSubscription({ ...newSubscription, title: e.target.value })}
                      placeholder="Enter subscription title"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleCreateSubscription}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Create Subscription
                </button>
                <button
                  type="button"
                  onClick={() => setIsSubscriptionModalOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Payment Modal */}
      {isPaymentModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Create Recurring Payment</h3>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="priceUSD" className="block text-sm font-medium text-gray-700">Price (USD)</label>
                    <input
                      type="number"
                      id="priceUSD"
                      value={newPayment.priceUSD}
                      onChange={(e) => setNewPayment({ ...newPayment, priceUSD: e.target.value })}
                      placeholder="Enter price in USD"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceType" className="block text-sm font-medium text-gray-700">Service Type</label>
                    <select
                      id="serviceType"
                      value={newPayment.serviceType}
                      onChange={(e) => setNewPayment({ ...newPayment, serviceType: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select a service type</option>
                      <option value="missed_call_answering">Missed Call Answering</option>
                      <option value="full_service_phone_line">Full-Service Phone Line</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                      type="text"
                      id="title"
                      value={newPayment.title}
                      onChange={(e) => setNewPayment({ ...newPayment, title: e.target.value })}
                      placeholder="Enter payment title"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700">Payment Method</label>
                    {paymentMethods.length > 0 ? (
                      <select
                        id="paymentMethod"
                        value={selectedPaymentMethod}
                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="">Select a payment method</option>
                        {paymentMethods.map((method) => (
                          <option key={method.id} value={method.id}>
                            {method.card.brand} **** **** **** {method.card.last4}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <p className="mt-1 text-sm text-red-600">No payment methods available. Please add a payment method first.</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleCreateRecurringPayment}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Create Payment Link
                </button>
                <button
                  type="button"
                  onClick={() => setIsPaymentModalOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageUser;