import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome, FiUser, FiPhone, FiPhoneCall, FiSettings, FiLogOut, FiShield, FiBriefcase, FiCreditCard, FiCode } from 'react-icons/fi';
import { useAuth } from '../context/AuthContext';

function Sidebar() {
  const location = useLocation();
  const { logout, user } = useAuth();
  console.log('User object in Sidebar:', user);

  const isActive = (path) => location.pathname === path;

  return (
    <aside className="w-64 bg-white shadow-md h-screen flex flex-col">
      <div className="p-4">
        <h2 className="text-2xl font-semibold text-gray-800">Zero Missed Calls</h2>
      </div>
      <div className="px-4 py-2 text-gray-700">
        <span role="img" aria-label="smiley">👋</span> Hi, {user.fullName}!
      </div>
      <nav className="flex-1 mt-4">
        <Link to="/calls" className={`flex items-center px-4 py-2 mt-2 ${isActive('/calls') ? 'text-gray-700 bg-gray-200' : 'text-gray-600 hover:bg-gray-200'}`}>
          <FiPhoneCall className="mr-3" />
          Calls
        </Link>
        <Link to="/billing" className={`flex items-center px-4 py-2 mt-2 ${isActive('/billing') ? 'text-gray-700 bg-gray-200' : 'text-gray-600 hover:bg-gray-200'}`}>
          <FiCreditCard className="mr-3" />
          Billing
        </Link>
        <Link to="/profile" className={`flex items-center px-4 py-2 mt-2 ${isActive('/profile') ? 'text-gray-700 bg-gray-200' : 'text-gray-600 hover:bg-gray-200'}`}>
          <FiUser className="mr-3" />
          Profile
        </Link>
      </nav>
      <div className="p-4">
        {user.permissionLevel === 1 && (
          <Link to="/manager" className={`flex items-center px-4 py-2 mt-2 ${isActive('/manager') ? 'text-gray-700 bg-gray-200' : 'text-gray-600 hover:bg-gray-200'}`}>
            <FiBriefcase className="mr-3" />
            Manager
          </Link>
        )}
        {user.permissionLevel === 2 && user.dev === "1" && (
          <Link to="/dev" className={`flex items-center px-4 py-2 mt-2 ${isActive('/dev') ? 'text-gray-700 bg-gray-200' : 'text-gray-600 hover:bg-gray-200'}`}>
            <FiCode className="mr-3" />
            Dev {user.permissionLevel !== 2 && '(Requires Admin)'} {user.dev !== "1" && '(Requires Dev Access)'}
          </Link>
        )}
        {user.permissionLevel === 2 && (
          <Link to="/admin" className={`flex items-center px-4 py-2 mt-2 ${isActive('/admin') ? 'text-gray-700 bg-gray-200' : 'text-gray-600 hover:bg-gray-200'}`}>
            <FiShield className="mr-3" />
            Admin
          </Link>
        )}
        <button
          onClick={logout}
          className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-200 w-full"
        >
          <FiLogOut className="mr-3" />
          Logout
        </button>
      </div>
    </aside>
  );
}

export default Sidebar;