import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Sidebar from './Sidebar';
import ReactMarkdown from 'react-markdown';

function VersionUpdate() {
  const [masterPathwayId, setMasterPathwayId] = useState('328b1b7d-c2cc-49e1-aa97-55e0f21079fc');
  const [masterNodes, setMasterNodes] = useState([]);
  const [masterEdges, setMasterEdges] = useState([]);
  const [allPathways, setAllPathways] = useState([]);
  const [selectedPathways, setSelectedPathways] = useState([]);
  const [updateTitle, setUpdateTitle] = useState('');
  const [updateChangelog, setUpdateChangelog] = useState('');
  const [summaryOfChanges, setSummaryOfChanges] = useState('');
  const [error, setError] = useState(null);

  const BLAND_API_KEY = 'sk-fqlnz6nuzcky3uzj0qjhhvdqktjdvf2zyh3b276kifvgwklhg5oyfkxak3olzvab69';

  useEffect(() => {
    fetchAllPathways().catch(err => {
      console.error('Failed to fetch pathways:', err);
      setError('Failed to fetch pathways. Please try again later.');
    });
  }, []);

  const fetchAllPathways = async () => {
    try {
      const response = await fetch('https://api.bland.ai/v1/convo_pathway', {
        method: 'GET',
        headers: { authorization: BLAND_API_KEY },
      });
      const data = await response.json();
      console.log('All pathways data:', data);
  
      if (data.data && Array.isArray(data.data)) {
        setAllPathways(data.data);
      } else if (Array.isArray(data)) {
        setAllPathways(data);
      } else {
        console.error('Unexpected data structure for all pathways:', data);
        setAllPathways([]);
        setError('Failed to fetch pathways. Unexpected data structure.');
      }
    } catch (error) {
      console.error('Error fetching pathways:', error);
      setAllPathways([]);
      setError(`Failed to fetch pathways: ${error.message}`);
    }
  };
  
  const handleGetMasterNodes = async () => {
    try {
      // First, fetch all versions of the master pathway
      const versionsResponse = await fetch(`https://api.bland.ai/v1/pathway/${masterPathwayId}/versions`, {
        method: 'GET',
        headers: { authorization: BLAND_API_KEY },
      });
      const versionsData = await versionsResponse.json();
      console.log('Master pathway versions:', versionsData);
  
      // Find the latest version
      const latestVersion = versionsData.find(version => version.is_latest);
      if (!latestVersion) {
        throw new Error('No latest version found for the master pathway');
      }
  
      // Fetch the data for the latest version
      const response = await fetch(`https://api.bland.ai/v1/pathway/${masterPathwayId}/version/${latestVersion.version_number}`, {
        method: 'GET',
        headers: { authorization: BLAND_API_KEY },
      });
      const data = await response.json();
      console.log('Master pathway data:', data);
  
      if (data.nodes && Array.isArray(data.nodes)) {
        setMasterNodes(data.nodes);
        setMasterEdges(data.edges || []);
        setError(null);
        console.log('Master nodes:', data.nodes);
        console.log('Master edges:', data.edges);
      } else {
        setError('Unexpected data structure for master pathway');
        console.error('Unexpected data structure:', data);
      }
    } catch (error) {
      console.error('Error fetching master pathway:', error);
      setError(`Failed to fetch master pathway: ${error.message}`);
    }
  };

  const handlePathwaySelection = (pathwayId) => {
    setSelectedPathways(prev => 
      prev.includes(pathwayId) ? prev.filter(id => id !== pathwayId) : [...prev, pathwayId]
    );
  };

  const createNewVersion = async (pathwayId, updatedNodes, updatedEdges) => {
    try {
      const pathwayToUpdate = allPathways.find(p => p.id === pathwayId);
      if (!pathwayToUpdate) {
        throw new Error(`Pathway with id ${pathwayId} not found`);
      }
  
      const response = await fetch(`https://api.bland.ai/v1/pathway/${pathwayId}/version`, {
        method: 'POST',
        headers: { 
          authorization: BLAND_API_KEY,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: `${pathwayToUpdate.name} - ${updateTitle}`,
          nodes: updatedNodes,
          edges: updatedEdges
        }),
      });
      const data = await response.json();
      console.log(`Created new version for pathway ${pathwayId}:`, data);
      return data;
    } catch (error) {
      console.error(`Error creating new version for pathway ${pathwayId}:`, error);
      throw error;
    }
  };

  const handlePushUpdate = async () => {
    let overallSummary = '';
  
    const masterGlobalPrompt = masterNodes[0]?.data?.globalPrompt || '';
    const globalPromptSuffix = masterGlobalPrompt.split('=====ENDCOMPANYSPECIFIC=====')[1] || '';
  
    for (const pathwayId of selectedPathways) {
      try {
        // Fetch the latest version of the pathway
        const versionsResponse = await fetch(`https://api.bland.ai/v1/pathway/${pathwayId}/versions`, {
            method: 'GET',
            headers: { authorization: BLAND_API_KEY },
          });
          const versionsData = await versionsResponse.json();
          const latestVersion = versionsData.find(version => version.is_latest);
          if (!latestVersion) {
            throw new Error(`No latest version found for pathway ${pathwayId}`);
          }

        // Fetch the data for the latest version
        const response = await fetch(`https://api.bland.ai/v1/pathway/${pathwayId}/version/${latestVersion.version_number}`, {
            method: 'GET',
            headers: { authorization: BLAND_API_KEY },
        });
        const pathwayData = await response.json();
        console.log(`Pathway data for ${pathwayId}:`, JSON.stringify(pathwayData, null, 2));
  
        if (!pathwayData || !pathwayData.nodes || !pathwayData.edges) {
          throw new Error(`Missing required data in pathway ${pathwayId}`);
        }
  
        const pathwayNodes = pathwayData.nodes;
        const pathwayEdges = pathwayData.edges;
  
        let addedNodes = [];
        let updatedNodes = [];
        let removedNodes = [];
        let addedEdges = [];
        let updatedEdges = [];
        let removedEdges = [];
  
        const updatedNodesArray = masterNodes.map(masterNode => {
          const existingNode = pathwayNodes.find(n => n.id === masterNode.id);
          
          const nodeGlobalPrompt = existingNode?.data?.globalPrompt || masterNode.data.globalPrompt || '';
          const [companySpecific] = nodeGlobalPrompt.split('=====ENDCOMPANYSPECIFIC=====');
          const updatedGlobalPrompt = `${companySpecific}=====ENDCOMPANYSPECIFIC=====${globalPromptSuffix}`;
  
          const updatedNode = {
            ...masterNode,
            data: {
              ...masterNode.data,
              globalPrompt: updatedGlobalPrompt
            }
          };
  
          if (!existingNode) {
            addedNodes.push(updatedNode.data.name);
          } else if (JSON.stringify(existingNode) !== JSON.stringify(updatedNode)) {
            updatedNodes.push(updatedNode.data.name);
          }
          
          return updatedNode;
        });
  
        pathwayNodes.forEach(pathwayNode => {
          if (!masterNodes.some(n => n.id === pathwayNode.id)) {
            if (pathwayNode.data.name.includes('KEEP')) {
              updatedNodesArray.push(pathwayNode);
            } else {
              removedNodes.push(pathwayNode.data.name);
            }
          }
        });
  
        const updatedEdgesArray = masterEdges.map(masterEdge => {
          const existingEdge = pathwayEdges.find(e => e.id === masterEdge.id);
          if (!existingEdge) {
            addedEdges.push(masterEdge.id);
          } else if (JSON.stringify(existingEdge) !== JSON.stringify(masterEdge)) {
            updatedEdges.push(masterEdge.id);
          }
          return masterEdge;
        });
  
        pathwayEdges.forEach(pathwayEdge => {
          if (!masterEdges.some(e => e.id === pathwayEdge.id)) {
            if (pathwayEdge.data && pathwayEdge.data.name && pathwayEdge.data.name.includes('KEEP')) {
              updatedEdgesArray.push(pathwayEdge);
            } else {
              removedEdges.push(pathwayEdge.id);
            }
          }
        });
  
        const newVersionResult = await createNewVersion(pathwayId, updatedNodesArray, updatedEdgesArray);
        console.log(`New version created for pathway ${pathwayId}:`, newVersionResult);
  
        const pathwaySummary = `
            ### Changes for Pathway ${pathwayId}:
            **Added Nodes:** ${addedNodes.join('\n - ') || 'None'}
            **Updated Nodes:** ${updatedNodes.join('\n - ') || 'None'}
            **Removed Nodes:** ${removedNodes.join('\n - ') || 'None'}
            \n
            **Added Edges:** ${addedEdges.join('\n - ') || 'None'}
            **Updated Edges:** ${updatedEdges.join('\n - ') || 'None'}
            **Removed Edges:** ${removedEdges.join('\n - ') || 'None'}
            **New Version:** ${newVersionResult.data.version_number}
        `;
  
        overallSummary += pathwaySummary;
  
      } catch (error) {
        console.error(`Error processing pathway ${pathwayId}:`, error);
        setError(`Failed to update pathway ${pathwayId}. Error: ${error.message}`);
        overallSummary += `\n\nError updating pathway ${pathwayId}: ${error.message}`;
      }
    }
    
    const postedChangelog = updateChangelog + '\n' + overallSummary;
    const finalChangelog = updateChangelog;
  
    setSummaryOfChanges(overallSummary);
  
    try {
      await fetch('https://hook.us1.make.com/x2eyuo7f5blpkiurac7rnts2usj83sdk', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          updateTitle,
          changelog: postedChangelog,
          updatedPathways: selectedPathways
        }),
      });
    } catch (error) {
      console.error('Error sending webhook:', error);
      setError('Failed to send update notification. Please try again.');
    }
  
    setUpdateChangelog(finalChangelog);
  
    setMasterPathwayId('328b1b7d-c2cc-49e1-aa97-55e0f21079fc');
    setMasterNodes([]);
    setMasterEdges([]);
    setSelectedPathways([]);
    setUpdateTitle('');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">Version Update</h1>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <div className="flex space-x-6 mb-6">
          <div className="w-1/2 bg-white shadow rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-4">Master Pathway</h2>
            <input
              type="text"
              value={masterPathwayId}
              onChange={(e) => setMasterPathwayId(e.target.value)}
              placeholder="Master Pathway ID"
              className="w-full p-2 border rounded mb-2"
            />
            <button onClick={handleGetMasterNodes} className="w-full p-2 bg-blue-500 text-white rounded">
              Get Master Nodes
            </button>
            <div className="mt-4 h-96 overflow-auto">
              {masterNodes.length > 0 ? (
                masterNodes.map(node => (
                  <div key={node.id} className="mt-2 p-2 bg-gray-100 rounded shadow">
                    <span>{node.data.name}</span>
                  </div>
                ))
              ) : (
                <p>No master nodes found. Please check the Master Pathway ID and try again.</p>
              )}
            </div>
          </div>
          <div className="w-1/2 bg-white shadow rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-4">All Pathways</h2>
            <button className="w-full p-2 bg-green-500 text-white rounded mb-2" onClick={fetchAllPathways}>
              Refresh Pathways
            </button>
            <div className="h-96 overflow-auto">
              {error && <p className="text-red-500 mb-2">{error}</p>}
              {allPathways.length > 0 ? (
                allPathways.map(pathway => (
                  <div key={pathway.id} className="flex items-center mt-2 p-2 bg-gray-100 rounded shadow">
                    <input
                      type="checkbox"
                      id={pathway.id}
                      checked={selectedPathways.includes(pathway.id)}
                      onChange={() => handlePathwaySelection(pathway.id)}
                      className="mr-2"
                    />
                    <label htmlFor={pathway.id}>{pathway.name}</label>
                  </div>
                ))
              ) : (
                <p>No pathways found. Please try refreshing.</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Summary of Changes</h3>
            <details className="bg-white border rounded p-4 mb-4">
              <summary className="cursor-pointer">View Summary</summary>
              <ReactMarkdown>{summaryOfChanges}</ReactMarkdown>
            </details>
          </div>
          <input
            type="text"
            value={updateTitle}
            onChange={(e) => setUpdateTitle(e.target.value)}
            placeholder="Update Title"
            className="w-full p-2 border rounded mb-4"
          />
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Update Changelog</h3>
            <Editor
              apiKey="qr5qg2ursks26xiuyxk1ubnhfjo5q6jls8gr7zpeff262qmh"
              value={updateChangelog}
              onEditorChange={(content) => setUpdateChangelog(content)}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
            />
          </div>
          <button onClick={handlePushUpdate} className="w-full p-2 bg-blue-500 text-white rounded">
            Create New Versions
          </button>
        </div>
      </main>
    </div>
  );
}

export default VersionUpdate;