import React, { useState } from 'react';
import { FiPlay, FiPause, FiX, FiVolume2, FiVolumeX } from 'react-icons/fi';
import WavesurferPlayer from '@wavesurfer/react';

function AudioPlayer({ url, onClose }) {
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setDuration(ws.getDuration());
  };

  const togglePlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  };

  const handleSeek = (e) => {
    if (wavesurfer) {
      const seekTo = parseFloat(e.target.value);
      wavesurfer.seekTo(seekTo / duration);
      setCurrentTime(seekTo);
    }
  };

  const handlePlaybackRate = (rate) => {
    if (wavesurfer) {
      wavesurfer.setPlaybackRate(rate);
      setPlaybackRate(rate);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (wavesurfer) {
      wavesurfer.setVolume(newVolume);
      setIsMuted(newVolume === 0);
    }
  };

  const toggleMute = () => {
    if (wavesurfer) {
      if (isMuted) {
        wavesurfer.setVolume(volume);
        setIsMuted(false);
      } else {
        wavesurfer.setVolume(0);
        setIsMuted(true);
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-gray-800 to-gray-900 shadow-lg border-t border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <WavesurferPlayer
          height={60}
          waveColor="#4B5563"
          progressColor="#60A5FA"
          cursorColor="#F59E0B"
          url={url}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onAudioprocess={(ws) => setCurrentTime(ws.getCurrentTime())}
        />
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center space-x-4">
            <button onClick={togglePlayPause} className="text-2xl text-blue-400 hover:text-blue-300 transition-colors duration-150">
              {isPlaying ? <FiPause /> : <FiPlay />}
            </button>
            <div className="flex flex-col w-64">
              <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSeek}
                className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-blue-400"
              />
              <div className="flex justify-between text-xs text-gray-400 mt-1">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <button onClick={toggleMute} className="text-gray-400 hover:text-gray-300">
                {isMuted ? <FiVolumeX /> : <FiVolume2 />}
              </button>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="w-20 h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-blue-400"
              />
            </div>
            <div className="flex items-center space-x-2">
              {[0.5, 1, 1.5, 2].map((rate) => (
                <button
                  key={rate}
                  onClick={() => handlePlaybackRate(rate)}
                  className={`px-2 py-1 rounded text-xs ${
                    playbackRate === rate
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  } transition-colors duration-150`}
                >
                  {rate}x
                </button>
              ))}
            </div>
          </div>
          <button onClick={onClose} className="text-xl text-gray-400 hover:text-gray-300 transition-colors duration-150">
            <FiX />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AudioPlayer;
