import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { FiCode } from 'react-icons/fi';

function DevPage() {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8 overflow-auto">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">Dev Page</h1>
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Developer Tools</h2>
          <ul className="space-y-2">
            <li>
              <Link to="/dev/update" className="flex items-center text-blue-500 hover:text-blue-700">
                <FiCode className="mr-2" />
                Pathway Update
              </Link>
            </li>
            <li>
              <Link to="/dev/version-update" className="flex items-center text-blue-500 hover:text-blue-700">
                <FiCode className="mr-2" />
                Version Update
              </Link>
            </li>
            {/* Add more dev tools links here as needed */}
          </ul>
        </div>
      </main>
    </div>
  );
}

export default DevPage;
